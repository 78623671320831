<template>
<div class="fit sticky-form-actions">
    <q-btn class="close-panel right top position-absolute" color="grey-8" @click.prevent="closePanel" type="button" icon="close" size="12px" flat dense />
    <b-tabs borderless :style="'overflow-y: auto; height: 100vh; padding-bottom: ' + formActionsRowHeight + 'px; '">
        <b-tab class="p-0">
            <template slot="title">
                <q-icon name="add" class="mr-1" />{{ translate('add_role') }}
            </template>
            <b-list-group class="list-group-accent">
                <b-form @submit.prevent style="overflow: hidden;">
                    <b-list-group-item class="p-0 pt-2">
                        <q-input class="m-0" dense square color="primary" type="text" id="name" v-model="role.name" :placeholder="translate('name')" />
                    </b-list-group-item>
                </b-form>
                <div class="form-actions bottom bg-light" :style="'width: ' + slideoutTabsWidth + 'px'">
                    <q-item class="p-3">
                        <q-item-section side>
                            <q-btn type="submit" no-caps color="info" @click.prevent="registerRole">
                                <q-icon size="1rem" name="check" class="mr-1" /> {{ translate('save') }}
                            </q-btn>
                        </q-item-section>
                        <q-item-section></q-item-section>
                        <q-item-section side>
                            <q-btn @click.prevent="closePanel" type="button" no-caps color="dark">
                                <q-icon size="1rem" name="close" class="mr-1" /> {{ translate('cancel') }}
                            </q-btn>
                        </q-item-section>
                    </q-item>
                </div>
            </b-list-group>
        </b-tab>
    </b-tabs>
</div>
</template>

<script>
import _ from 'lodash'

export default {
    name: 'AddRole',
    data: function () {
        return {
            formActionsRowHeight: 0,
            slideoutTabsWidth: 0,

            role: {
                name: ''
            },
            selected: null,
        }
    },
    created: function () {
        this.getElementsWidthHeight()
    },
    beforeDestroy: function () {
        window.removeEventListener('resize', this.getElementsWidthHeight)
    },
    mounted: function () {
        window.addEventListener('resize', this.getElementsWidthHeight)
    },
    methods: {
        getElementsWidthHeight: _.debounce(function () {
            this.formActionsRowHeight = document.querySelector('.form-actions').clientHeight
            this.slideoutTabsWidth = document.querySelector('.slideout .tabs').clientWidth
        }, 200),
        registerRole: function () {
            //temp - need to abort if accessToken() is null
            if (this.accessToken() == null) {
                return this.$router.push({
                    name: 'Login'
                })
            }

            var data = {
                'role_name': this.role.name,
            }

            var headers = {
                'Authorization': 'Bearer ' + this.accessToken(),
                'Content-Type': 'application/json'
            }

            axios.post(baseUrl + 'rights/roles', data, {
                    headers: headers
                })
                .then(response => {
                    var key = response.data.key ? '_' + response.data.key : ''
                    var message = 'add_role_' + response.data.msg + key
                    var translated_message = this.translate(message)

                    if (response.data.status) {
                        this.$toasted.success(translated_message, {
                            duration: this.$toasted_duration
                        })
                        this.$emit('closePanel', {
                            status: response.data.status
                        })
                    } else {
                        this.$toasted.error(translated_message, {
                            duration: this.$toasted_duration
                        })
                    }
                })
                .catch(error => {
                    this.$toasted.error('An error has occured', {
                        duration: this.$toasted_duration
                    })

                })
        },
        closePanel() {
            this.$emit('closePanel', {})
        }
    }
}
</script>
