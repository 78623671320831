<template>
<div class="fit">
    <q-btn class="close-panel right top position-absolute" color="grey-8" @click.prevent="closePanel" type="button" icon="close" size="12px" flat dense />
    <b-tabs borderless>
        <b-tab class="p-0">
            <template slot="title">
                <i class="mt-1 mb-2 fas fa-trash"></i>
            </template>
            <b-list-group class="list-group-accent">
                <q-separator />
                <b-list-group-item class="list-group-item-accent-danger bg-light font-weight-bold font-small">
                    <q-icon size="1rem" color="red" name="delete" />&nbsp;
                    {{ translate('delete_role') }}
                </b-list-group-item>
                <q-separator />
                <b-list-group-item class="list-group-item-accent-info">
                    <q-item class="p-0">
                        <q-item-section>
                            <q-item-label line="1">{{ translate('deleting_role') }}</q-item-label>
                        </q-item-section>
                    </q-item>
                </b-list-group-item>
                <q-separator />
                <b-list-group-item class="list-group-item-accent-info ">
                    <q-item class="p-0 full-width">
                        <q-item-section side>
                            <q-btn no-caps @click="deleteRole" type="submit" color="danger">
                                <q-icon class="mr-1" size="1rem" name="check" /> {{ translate('yes') }}
                            </q-btn>
                        </q-item-section>
                        <q-item-section></q-item-section>
                        <q-item-section side>
                            <q-btn no-caps @click.prevent="closePanel" type="button" color="secondary" class="text-dark">
                                <q-icon class="mr-1" size="1rem" name="cancel" /> {{ translate('no') }}
                            </q-btn>
                        </q-item-section>
                    </q-item>
                </b-list-group-item>
            </b-list-group>
        </b-tab>
    </b-tabs>
</div>
</template>

<script>
import { eventBus } from '../../../main'

export default {
    name: 'DeleteRole',
    props: ['role_id'],
    data() {
        return {
            msg: '',
        };
    },
    methods: {
        deleteRole: function () {
            var roleUrl = baseUrl + 'rights/roles/' + this.role_id
            var headers = {
                'Authorization': 'Bearer ' + this.accessToken(),
            };

            axios.delete(roleUrl, {
                    headers: headers
                })
                .then(response => {
                    var key = response.data.key ? '_' + response.data.key : ''
                    var message = 'delete_role_' + response.data.msg + key
                    var translated_message = this.translate(message)
                    if (response.data.status) {
                        this.$toasted.success(translated_message, {
                            duration: this.$toasted_duration
                        })
                        this.$emit('closePanel', {
                            status: response.data.status
                        })
                        eventBus.$emit('delete_role', this.role_id)
                    } else {
                        this.$toasted.error(translated_message, {
                            duration: this.$toasted_duration
                        })
                    }
                })
                .catch(function (error) {
                    // console.log(error);
                });
        },
        closePanel() {
            this.$emit('closePanel', {
                response: 'dsfd'
            });
        }
    }
}
</script>
