<template>
<div>
    <b-card class="m-0" no-body>
        <div slot="header">
            <q-item class="full-width m-o p-0">
                <q-item-section side class="p-0">
                    <h3>{{ translate('roles') }}</h3>
                </q-item-section>
                <q-space />
                <q-item-section side class="p-0">
                    <q-btn v-if="!$q.screen.lt.sm" @click.stop.prevent="addRolePanel" type="button" no-caps color="primary">
                        <i class="fas fa-plus mr-1"></i> {{ translate('add_role') }}
                    </q-btn>
                    <q-btn v-if="$q.screen.lt.sm" class="bg-white text-primary" icon="add" dense round no-caps color="primary" type="button" @click.stop.prevent="addRolePanel" />
                </q-item-section>
            </q-item>
        </div>
    </b-card>
    <b-list-group class="list-group">
        <q-item v-for="(role, i) in roles" :key="'role-item-' + i + '-' + (i.id,role.id)" :id="role.id" class="list-item full-width">
            <q-item-section class="p-0 pl-md-2" side>
                <div class="image-thumbnail cursor-pointer" @click.prevent="showEditRolePanel(i)">
                    <i class="fa fa-photo fa-2x"></i>
                </div>
            </q-item-section>
            <q-item-section class="full-height align-self-center pt-2 pb-2">
                <strong class="text-muted">{{ translate(role) }}</strong>
            </q-item-section>
            <!-- [+] Desktop sections. -->
            <template v-if="!$q.screen.lt.sm">
                <q-item-section side class="full-height align-self-center pt-2 pb-2">
                    <q-btn round icon="edit" color="primary" @click="showEditRolePanel(i)">
                        <q-tooltip :offset="[10, 10]">{{ translate('edit_' + entity_type) }}</q-tooltip>
                    </q-btn>
                </q-item-section>
                <q-item-section side class="full-height align-self-center pt-2 pb-2">
                    <q-btn round icon="delete" color="danger" @click="showDeletePanel(i)">
                        <q-tooltip :offset="[10, 10]">{{ translate('delete_' + entity_type) }}</q-tooltip>
                    </q-btn>
                </q-item-section>
            </template>
            <!-- [-] Desktop sections. -->
        </q-item>
    </b-list-group>
</div>
</template>

<script>
import _ from 'lodash'
import { eventBus } from '../../main'
import AddRole from './forms/AddRole'
import EditRole from './forms/EditRole'
import DeleteRole from './forms/DeleteRole'

export default {
    name: 'Roles',
    computed: {
        entity_type: function () {
            return 'role'
        },
    },
    data: function () {
        return {
            roles: {},
            searchQuery: '',
            pageSize: 9,
            page: 1,
            isTyping: false,
            isLoading: false,
            loadMore: true,
        }
    },
    created() {
        this.getRoles()
    },
    mounted: function () {
        eventBus.$on('delete_role', (id) => {
            this.deleteRole(id)
        })
    },
    watch: {
        searchQuery: _.debounce(function () {
            this.isTyping = false
        }, 200),
        isTyping: function (value) {
            if (!value) {
                this.resetMainList()
                this.getRoles()
            }
        }
    },
    methods: {
        updateRoleName: function (item) {
            // Update the element from the roles.
            this.roles[item.role.id] = item.role.name ? item.role.name : ''
        },
        deleteRole: function (id) {
            this.resetMainList()
            this.getRoles()
        },
        resetMainList: function () {
            this.page = 1
            this.roles = {}
        },
        getRoles: function () {
            this.isLoading = true
            var roleUrl = baseUrl + 'rights/roles?page=' + this.page + '&items_per_page=' + this.$items_per_page + '&search=' + this.searchQuery
            var headers = {
                'Authorization': 'Bearer ' + this.accessToken(),
            }

            axios.get(roleUrl, {
                    headers: headers
                })
                .then(response => {
                    this.isLoading = false
                    this.roles = response.data.items
                    response.data.items.length < this.pageSize && (this.loadMore = false)
                    var index = 1
                })
                .catch(function (error) {
                    // console.log(error)
                })
        },
        showDeletePanel(id) {
            const panelInstance = this.$showPanel({
                component: DeleteRole,
                props: {
                    role_id: id
                }
            })
        },
        addRolePanel() {
            const panelInstance = this.$showPanel({
                component: AddRole,
            })

            panelInstance.promise.then(result => {
                if (result && result.status) {
                    this.resetMainList()
                    this.getRoles()
                }
            })
        },
        showEditRolePanel(id, index) {
            const panelInstance = this.$showPanel({
                component: EditRole,
                props: {
                    role_id: id,
                    index: index
                }
            })

            panelInstance.promise
                .then(result => {
                    if (result && result.status) {
                        this.updateRoleName(result)
                    }
                })
        },
    }
}
</script>

<style lang="scss" scoped>
.search-input {
    margin-left: -15px !important;
    margin-right: -15px !important;
}

.q-item.list-item {
    background: white;
    border-width: 1px;
    border-style: solid;
    border-color: rgba(0, 0, 0, .16);
    margin: 0 0 .5rem;
    padding-left: 0;
    padding-top: 0;
    padding-bottom: 0;
    transition: border-color .1s ease-in-out;

    &:first-child {
        margin-top: .5rem;
    }

    &:hover {
        border-color: rgba(0, 0, 0, .26);
    }
}
</style>
